import React, { useState } from 'react'

const ENDPOINT = `${process.env.SITE_URL}/api/search`

export default function Search() {
  const [query, setQuery] = useState('')
  const onSubmit = (e) => {
    e.preventDefault()

    async function fetchData() {
      // post query to ENDPOINT
      const res = await fetch(ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query, locale: 'de' })
      })
      const _res = await res.json()
    }
    fetchData()
  }
  return (
    <div>
      <form onSubmit={onSubmit}>
        <input value={query} placeholder='search' onChange={(e) => setQuery(e.target.value.toString())} />
        <button type="submit">search</button>
      </form>
    </div>
  )
}